// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/grudges/grudge-darker.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/grudges/grudge-dark.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/images/grudges/grudge-green.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/images/grudges/grudge-gold.png");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../assets/images/grudges/grudge-white.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".review-slide{transition:all .2s ease-in-out 0s}.review-slider{position:relative;display:flex;flex-direction:column;align-items:center;width:100%;margin:10px 0;padding:40px 0;background:#eccc8a}.review-slider.grudge-darker{background:#122a15}.review-slider.grudge-darker h1,.review-slider.grudge-darker h2{color:#fff}.review-slider.grudge-darker:after,.review-slider.grudge-darker:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.review-slider.grudge-dark{background:#3d6136}.review-slider.grudge-dark h1,.review-slider.grudge-dark h2{color:#fff}.review-slider.grudge-dark:after,.review-slider.grudge-dark:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.review-slider.grudge-green{background:#cae0c7}.review-slider.grudge-green:after,.review-slider.grudge-green:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.review-slider.grudge-gold{background:#eccc8a}.review-slider.grudge-gold:after,.review-slider.grudge-gold:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.review-slider.grudge-white{background:#fff}.review-slider.grudge-white:after,.review-slider.grudge-white:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")}.review-slider:after,.review-slider:before{content:\"\";position:absolute;right:0;left:0;height:50px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");background-size:100%}.review-slider:before{top:-30px}.review-slider:after{bottom:-50px;transform:rotateX(180deg)}.review-slider h2{margin:40px 0 60px;color:#3d6136;font-weight:400;font-size:40px;font-family:\"Bodoni-Hand-Regular\",\"helvetica\",\"verdana\",sans-serif;line-height:40px}.review-slider--container{width:100%;max-width:1300px;margin-bottom:20px}@media screen and (max-width:940px){.review-slider h2{font-size:30px;line-height:38px;margin-left:25px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
