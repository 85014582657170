<template lang="pug">
.review-slider(:class="grudeClass(color)")
  .review-slider--container
    h2 Wat zeggen anderen
    slider(:options="options")
      .review-wrapper.slider-wrapper
        slot
</template>

<script>
import { grudgeMixin } from '@/mixins/colorMixin';

export default {
  name: 'ReviewSlider',
  components: {
    slider: () => import('@/components/slider'),
  },
  mixins: [grudgeMixin],
  props: {
    color: {
      type: String,
      default: 'yellow',
    },
  },
  data() {
    return {
      options: {
        wrapperClass: 'review-wrapper',
        slideClass: 'review-slide',
        slidesPerView: 'auto',
        spaceBetween: 30,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        loop: false,
        breakpoints: {
          1000: {
            slidesPerView: 3,
            spaceBetween: 20,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
          },
        },
      },
    };
  },
};
</script>

<style lang="scss">
.review-slide {
  transition: all 0.2s 0s ease-in-out;
}

.review-slider {
  @include grudge-backgrounds();
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 10px 0;
  padding: 40px 0;
  background: $yellowCrayola;

  &::before, &::after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    height: 50px;
    background-image: url('~assets/images/grudges/grudge-gold.png');
    background-size: 100%;
  }

  &::before {
    top: -30px;
  }

  &::after {
    bottom: -50px;
    transform: rotateX(180deg);
  }

  h2 {
    margin: 40px 0 60px 0;
    color: $hunterGreen;
    font-weight: 400;
    font-size: 40px;
    font-family: $fontBodoni;
    line-height: 40px;
  }

  &--container {
    width: 100%;
    max-width: 1300px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: $breakpoint-md) {
    h2 {
      font-size: 30px;
      line-height: 38px;
      margin-left: 25px;
    }
  }
}
</style>
